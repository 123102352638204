<div md-dialog-content>
    <p style="padding: 24px; font-weight: bold">
        For those institutions that accept international applications,
        please enter the closest approximation possible for the number of "genuinely interested"
        international applicants in the "Applications" fields.
        Some NACCAP institutions receive significant numbers of unqualified and "dead-end"
        applications from international populations, which can impact funnel yield metrics in a
        non-representative manner.
    </p>
</div>
<div mat-dialog-actions align="center">
    <button mat-raised-button class="btn btn--gold" mat-dialog-close tabindex="-1"
            (click)="onConfirmClick()"
    >
        I understand
    </button>
</div>
