import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartjsModule } from 'ng-chartjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill';

import { AppComponent } from './app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { CmsEntryComponent } from '@app/cms/cms-entry.component';
import { CmsEntryHomepageComponent } from '@app/cms/cms-entry-homepage.component';
import { CmsEntrySubpageComponent } from '@app/cms/cms-entry-subpage.component';
import { CmsEntryContactUsComponent } from '@app/cms/cms-entry-contact-us.component';
import { CmsEntryForgotPasswordComponent } from '@app/cms/cms-entry-forgot-password.component';
import { CmsEntryAbsLandingComponent } from '@app/cms/cms-entry-abs-landing.component';
import { CmsEntryAbsListComponent } from '@app/cms/cms-entry-abs-list.component';
import { CmsEntryAbsSummaryComponent } from '@app/cms/cms-entry-abs-summary.component';
import { CmsEntryAbsSubmitComponent } from '@app/cms/abs-submit/cms-entry-abs-submit.component';
import { CmsEntry404Component } from '@app/cms/cms-entry-404.component';
import { CmsEntryLoginComponent } from '@app/cms/cms-entry-login.component';
import { SessionHttpInterceptor } from '@app/session-http-interceptor';
import { CmsEntryFunnelLandingComponent } from '@app/cms/cms-entry-funnel-landing.component';
import { CmsEntryFunnelSummaryComponent } from '@app/cms/cms-entry-funnel-summary.component';
import { CmsEntryFunnelReportComponent } from '@app/cms/cms-entry-funnel-report.component';
import { CmsEntryWatchlistComponent } from '@app/cms/cms-entry-watchlist.component';
import { CmsEntryAdminAbsToolComponent } from '@app/cms/cms-entry-admin-abs-tool.component';
import { LoginComponent } from '@app/core/login/login.component';
import { PaginationComponent } from '@app/core/pagination/pagination.component';
import { ChartComponent } from './core/chart/chart.component';
import { FunnelSummarySectionComponent } from '@app/cms/funnel-summary-section/funnel-summary-section.component';
import { FunnelReportFilterComponent } from '@app/cms/funnel-report-filter/funnel-report-filter.component';
import { AlertComponent } from './core/alert/alert.component';
import { AdminReportActionsComponent } from './core/admin-report-actions/admin-report-actions.component';
import { CmsEntryAbsSubmitPartAComponent } from '@app/cms/abs-submit/cms-entry-abs-submit-part-a.component';
import { CmsEntryAbsSubmitPartBComponent } from '@app/cms/abs-submit/cms-entry-abs-submit-part-b.component';
import { CmsEntryAbsSubmitPartCComponent } from '@app/cms/abs-submit/cms-entry-abs-submit-part-c.component';
import { CmsEntryAbsSubmitPartDComponent } from '@app/cms/abs-submit/cms-entry-abs-submit-part-d.component';
import { CmsEntryReportHistoryComponent } from '@app/cms/cms-entry-report-history.component';
import { CmsEntryMyAccountComponent } from '@app/cms/cms-entry-my-account.component';
import { CurrencyInputDirective } from './core/currency-input.directive';
import { CurrencyPipe } from './core/currency.pipe';
import { ControlErrorsComponent } from '@app/cms/abs-submit/control-errors/control-errors.component';
import { AbsSubmitWrapperComponent } from '@app/cms/abs-submit/abs-submit-wrapper/abs-submit-wrapper.component';
import { AbsSubmitSaveMessagesComponent } from '@app/cms/abs-submit/abs-submit-save-messages/abs-submit-save-messages.component';
import { AbsSubmitCommentComponent } from '@app/cms/abs-submit/abs-submit-comment/abs-submit-comment.component';
import { AbsSubmitFieldComponent } from '@app/cms/abs-submit/abs-submit-field/abs-submit-field.component';
import { NumberInputDirective } from './core/number-input.directive';
import { DropdownDirective } from './core/dropdown/dropdown.directive';
import { BaseTemplateComponent } from './templates/base-template.component';
import { FullResourcePath } from '@app/core/pipes/full-resource-path.pipe';
import { Filename } from '@app/core/pipes/filename.pipe';
import { AdminAbsSummaryFiltersComponent } from './admin/admin-abs-summary-filters/admin-abs-summary-filters.component';
import { AdminAbsTableComponent } from './admin/admin-abs-table/admin-abs-table.component';
import { SaveScreenshotComponent } from './admin/save-screenshot/save-screenshot.component';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
	declarations: [
		AppComponent,
		CmsEntryComponent,
		CmsEntryLoginComponent,
		CmsEntryHomepageComponent,
		CmsEntrySubpageComponent,
		CmsEntryContactUsComponent,
		CmsEntryForgotPasswordComponent,
		CmsEntryAbsLandingComponent,
		CmsEntryAbsListComponent,
		CmsEntryAbsSummaryComponent,
		CmsEntryAbsSubmitComponent,
		CmsEntry404Component,
		CmsEntryFunnelLandingComponent,
		CmsEntryFunnelSummaryComponent,
		CmsEntryFunnelReportComponent,
		CmsEntryWatchlistComponent,
		CmsEntryAdminAbsToolComponent,
		LoginComponent,
		PaginationComponent,
		ChartComponent,
		FunnelSummarySectionComponent,
		FunnelReportFilterComponent,
		AlertComponent,
		AdminReportActionsComponent,
		CmsEntryAbsSubmitPartAComponent,
		CmsEntryAbsSubmitPartBComponent,
		CmsEntryAbsSubmitPartCComponent,
		CmsEntryAbsSubmitPartDComponent,
		CmsEntryReportHistoryComponent,
		CmsEntryMyAccountComponent,
		CurrencyInputDirective,
		CurrencyPipe,
		ControlErrorsComponent,
		AbsSubmitWrapperComponent,
		AbsSubmitSaveMessagesComponent,
		AbsSubmitCommentComponent,
		AbsSubmitFieldComponent,
		NumberInputDirective,
		DropdownDirective,
		BaseTemplateComponent,
		FullResourcePath,
		Filename,
		AdminAbsSummaryFiltersComponent,
		AdminAbsTableComponent,
		SaveScreenshotComponent
	],
	imports: [
		BrowserModule,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		NgChartjsModule,
		MatDialogModule,
		QuillModule.forRoot({
			modules: {
				// default toolbar
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],
					['blockquote'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					[{ script: 'sub' }, { script: 'super' }],
					[{ color: [] }, { background: [] }], // dropdown with defaults from theme
					[{ align: [] }],
					['clean'],
					['link']
				]
			},
			theme: 'snow'
		})
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionHttpInterceptor,
			multi: true
		},
		CurrencyPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
